@import '../../styles/common.scss';

.typeSummaryCard {
  background: white;
  border: 1px solid var(--border-gray-0);
  box-sizing: border-box;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  @include non-sp {
    width: 327px;
  }
  @include sp {
    flex-grow: 1;
  }

  .header {
    @include use-yu-gothic;
    @include type-label-text;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 68px;
    @include non-sp {
      padding: 8px 16px;
    }
    @include sp {
      padding: mob(8) mob(16) mob(8) mob(14);
    }
  }
}
