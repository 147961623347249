@import '../../styles/common.scss';

.container {
  position: relative;
  padding: 60px 0;
  background-color: var(--pink-m1);
  @include sp {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.label {
  position: absolute;
  top: 50px;
  right: 750px;
  width: 120px;

  /* 画面幅が2560px以下の場合 */
  @media (max-width: 2560px) {
    top: 2vw;
    right: 28vw;
    width: 5vw;
  }

  /* 画面幅が1440px以下の場合 */
  @media (max-width: 1440px) {
    top: 4.5vw;
    right: 12.5vw;
    width: 8vw;
  }

  /* 画面幅が1024px以下の場合 */
  @media (max-width: 1024px) {
    top: 5vw;
    right: 3vw;
    width: 11vw;
  }

  /* 画面幅が768px以下の場合 */
  @media (max-width: 768px) {
    top: 3vw;
    right: 4vw;
    width: 13vw;
  }

  /* 画面幅が480px以下の場合 */
  @media (max-width: 480px) {
    top: 2vw;
    right: 2vw;
    width: 18.5vw;
  }

  /* 画面幅が320px以下の場合 */
  @media (max-width: 320px) {
    top: 4.5vw;
    right: 2.5vw;
    width: 19.5vw;
  }
}

.text {
  font-size: 27px;
  font-weight: bolder;
  margin-bottom: 20px;
  text-align: center;
  letter-spacing: 0.15em;
  @include sp {
    font-size: 18px;
  }
}
