@import '../../styles/common.scss';

.headerContainer {
  margin: 0 auto;
  text-align: center;
  background-size: cover;
  padding-top: 40px;
  padding-bottom: 64px;
  @include sp {
    padding-top: 0;
    padding-bottom: 24px;
  }
}

.heroWrapper {
  margin-bottom: 40px;
}

.heroWrapper picture img {
  max-width: 100%;
  margin: 0 auto;
  @include desktop {
    max-width: 750px;
  }
}