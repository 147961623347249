@import '../../styles/common.scss';

.radioWrapper {
  display: flex;
  align-items: center;
}
.radio {
  flex-shrink: 0;
  @include non-sp {
    width: 16px;
    height: 16px;
  }
  @include sp {
    width: mob(16);
    height: mob(16);
  }
}

.checkedIcon {
  color: var(--pink-1);
  width: 16px;
  height: auto;
  flex: 0 0 auto;
}

.radioCard {
  background: white;
  transition: all 0.2s ease;
  text-align: left;
  &.checked {
    background: var(--bg-gray-0);
    border-color: var(--primary);
  }
  border: 2px solid var(--border-gray-0);
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  @include use-europa-yu-gothic;
  @include non-sp {
    @include type-text;
  }
  @include sp {
    @include type-label-text;
  }
  font-weight: bold;
  @include non-sp {
    &.l {
      width: 100%;
    }
    &.m {
    }
    &.s {
    }
    min-width: 160px;
    padding: 20px;
    min-height: 152px;
  }
  @include sp {
    &.m {
    }
    &.s {
      width: mob(154);
    }
    padding: mob(16) mob(12) mob(16) mob(20);
  }
  .radio {
    @include non-sp {
      margin-right: 16px;
    }
    @include sp {
      margin-right: mob(20);
    }
  }
  .label {
    position: relative;
    display: flex;
    align-items: center;
  }

  .balloon {
    position: absolute;
    top: -38px;
    left: 38%;
  }

  .description {
    text-align: left;
    color: var(--text-black);
    font-weight: normal;
    margin-top: 8px;
    @include type-label-text;
    white-space: pre-line;
    white-space: pre-wrap;
  }
}

.radioCardFa {
  background: white;
  transition: all 0.2s ease;
  &.checked {
    background: var(--bg-gray-0);
  }
  border: 2px solid var(--border-gray-0);
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  @include use-europa-yu-gothic;
  @include non-sp {
    @include type-text;
  }
  @include sp {
    @include type-label-text;
  }
  font-weight: bold;
  @include non-sp {
    &.m {
    }
    &.s {
    }
    min-width: 160px;
    padding: 20px;
  }
  @include sp {
    &.m {
    }
    &.s {
      width: mob(154);
    }
    padding: mob(16) mob(12) mob(16) mob(20);
  }
  .radio {
    @include sp {
      margin-right: mob(20);
    }
  }
}

.radioRecommendLabel {
  padding: 4px 12px;
  background-color: #df5475;
  color: #ffffff;
  display: inline-block;
}

.radioRecommendLabelSmall {
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 1.6px;
  margin-top: 4px;

  @include sp {
    margin-top: 10px;
  }
}

.faTop {
  display: flex;
  align-items: center;
  .label {
  }
}

.faInput {
  display: block;
  flex: 1 1 auto;
  @include sp {
    min-height: mob(80);
    @include type-label-text;
    &::placeholder {
      @include type-label-text;
    }
  }
}

.faBottom {
  display: flex;
  @include non-sp {
    margin-left: 36px;
    margin-top: 8px;
    margin-right: 20px;
  }
  @include sp {
    margin-top: mob(8);
    margin-right: mob(12);
  }
}
