@import '../../styles/common.scss';

.container {
  background-color: #C0506B;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  margin: 40px auto;
  @include non-sp {
    width: 327px;
    height: 60px;
    &:hover {
      opacity: 0.8;
    }
  }

  @include sp {
    width: mob(325);
    height: mob(53);
  }
}
//
//.loader {
//  path {
//    color: white;
//    fill: white;
//  }
//  @include non-sp {
//    width: 32px;
//  }
//  @include sp {
//    width: 32px;
//  }
//}


.text {
  color: #FFF;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}

.svg {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 14px;
  display: flex;
  align-items: center;
}

.label {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  border: 2px solid #C0506B;
  border-radius: 50px;
  padding: 2px 20px;
  font-weight: bold;
  font-size: 13px;
  line-height: 20px;
  color: #C0506B;
  white-space: nowrap;
}
