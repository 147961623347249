@import '../../styles/common.scss';

.container {
  max-width: 750px;
  margin: auto;
  width: 100%;
  height: auto;
  @include sp {
    max-width: 95vw;
  }
}

.videoContainer {
  position: relative;
  padding-bottom: 56.25%;
  height: auto;
  width: 100%;
  overflow: hidden;

  iframe {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  &.hidden {
    display: none;
  }
  &.active {
    display: block;
  }
}

.videoPlaceholderButton {
  display: block;
  width: 100%;
}

.videoPlaceholderContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.videoPlaceholderImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
}

.videoPlaceholderCover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.videoPlayIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  color: #fff;
  border-radius: 50%;
  width: 70px;
  height: 70px;
}
