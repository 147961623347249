@import '../../styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include sp {
    gap: 0;
  }
}

.campaignContainer {
  max-width: 750px;
  margin: 0 auto;
  position: relative;

  .currentCpShowDetailToggle,
  .nextCpShowDetailToggle {
    position: absolute;
    width: 290px;
    height: 27px;
    cursor: pointer;
  }

  .currentCpShowDetailToggle,
  .nextCpShowDetailToggle {
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;

    @include sp {
      width: 75vw;
      margin: 24px 0 0 26px;
    }
  }

  .currentCpOpen,
  .nextCpOpen {
    bottom: 375px;

    @include sp {
      bottom: 177vw;
    }
  }

  .currentCpClose,
  .nextCpClose {
    bottom: 4px;

    @include sp {
      bottom: 1vw;
    }
  }
}
