@import '../../styles/common.scss';

.container {
  padding: 60px 0;
  background-color: var(--pink-light);
  @include sp {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.container img {
  width: 100%;
  height: auto;
  margin: auto;
  @include desktop {
    max-width: 750px;
  }
}
