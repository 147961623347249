@import '../../styles/common.scss';
.lessonListContent {
  max-width: 1000px;
  margin: 0 auto;
}

.headerText {
  font-size: 20px;
}

.sp {
  display: none;

  @include sp {
    display: block;
  }
}

.selectorWrapper {
  @include non-sp {
    max-width: 85%;
    margin: 48px auto;
  }

  @include sp {
    margin: mob(40) 0;
  }
}

.only {
  display: none;
}

.lessonInfo {
  background: var(--bg-gray-0);
  color: var(--primary);
  font-weight: bold;
  border-radius: 2px;
  padding: 16px 24px;
  font-size: 14px;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  @include non-sp {
    margin-bottom: 16px;
  }
}

.summaryWrapper {
  margin-top: 12px;
}

.lessonSummary {
  letter-spacing: 2px;

  @include non-sp {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .summaryBody {
    @include non-sp {
      margin-left: 36px;
    }

    @include sp {
      margin-top: 20px;
    }
  }
}

.hiddenListWrapper {
  position: relative;
  height: 120px;
  overflow: hidden;
  transition: all 0.3s;
}

.hiddenListWrapperShow {
  height: auto;
  transition: all 0.3s;
}

.hiddenListCover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 96%);
}

.hiddenListTogger {
  font-family: var(--font-family-europa-yu);
  margin: 24px auto;
  border: 0.5px solid #4c6072;
  box-sizing: border-box;
  border-radius: 112px;
  height: 40px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 1.6px;
  color: #4c6072;
}

.onlySp {
  @include sp {
    display: block !important;
  }
}

.stepWrapper {
  margin: 0 auto;
  max-width: 1000px;
}
