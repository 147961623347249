@import '../../styles/common.scss';

.selectedAreaWrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.areaCard {
  border: 1px solid var(--border-gray-0);
  height: 68px;
  line-height: 68px;
  @include sp {
    width: 100%;
  }
}
