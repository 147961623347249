@import '../../styles/common.scss';

.selectInput {
  display: flex;
  justify-content: flex-start;
  gap: 16px;

  @include sp {
    flex-direction: column;
    gap: 35px;
  }
}

.contentType {
  margin-top: 64px;
  @include sp {
    margin-top: 40px;
  }
}

.cardWrapper {
  position: relative;
  padding: 75px 16px 16px 16px;
}

.recommendOns:nth-of-type(1),
.normal:nth-of-type(2) {
  background: #f0f6f7;
  @include sp {
    padding-bottom: 24px;
  }
}

.normal:nth-of-type(1),
.recommendOns:nth-of-type(2) {
  background: var(--pink-light);
  @include sp {
    padding-bottom: 24px;
  }
}

.recommendLabel {
  position: absolute;
  width: 153px;
  top: -14px;
  left: 100px;
}

.cardHeader {
  width: 90%;
  display: flex;
  align-items: center;
  gap: 6px;
  position: absolute;
  top: -20px;

  @include sp {
    width: 93%;
  }
}

.rowReverse {
  @include sp {
    flex-direction: row-reverse;
  }
}

.sheGirl {
  width: 80px;
  height: 80px;
  margin: 0 !important;
}

.cardHeaderText {
  font-family: 'Brandon Grotesque';
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  line-height: 16px;
  letter-spacing: 1.6px;
  margin-top: 40px;
}

.card {
  position: relative;
  padding: 15px;
  @include non-sp {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  @include sp {
    &:not(:last-child) {
      margin-bottom: mob(32);
    }
  }
  &.l {
  }
  &.m {
  }
  &.s {
  }
}
