@import '../../styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.reskiliingContainer {
  max-width: 750px;
  margin: 0 auto;
  position: relative;
}

.showDetailToggle {
  position: absolute;
  left: 0;
  right: 0;
  width: 290px;
  height: 27px;
  cursor: pointer;

  @include sp {
    width: 76vw;
    margin: 24px 0 0 26px;
  }
}

.showPriceDescriptionDetailToggle {
  position: absolute;
  left: 0;
  right: 0;
  width: 170px;
  height: 27px;
  cursor: pointer;

  @include sp {
    width: 45vw;
    margin: 24px 0 0 26px;
  }
}

.linkToPricePageText {
  position: absolute;
  width: 66px;
  height: 27px;
  left: 7px;
  right: 0;
  top: 588px;
  cursor: pointer;

  @include sp {
    width: 14vw;
    margin: 24px 0 0 38vw;
    top: 237vw;
  }
}

.reskillingBannerDetailOpen {
  top: 269px;

  @include sp {
    top: 75vw;
  }
}

.reskillingBannerDetailClose {
  bottom: 4px;

  @include sp {
    bottom: 1vw;
  }
}

.priceDescriptionDetailOpen {
  top: 643px;

  @include sp {
    top: 260vw;
  }
}

.priceDescriptionDetailClose {
  bottom: 2px;
}
